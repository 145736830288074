import * as React from 'react';
import { createPortal } from 'react-dom';
import * as S from './Tooltip.theme';
import { useTooltip } from './useTooltip.hook';

export function Tooltip(): JSX.Element {
  const portalRef = document.getElementById('tooltip-root') as HTMLElement;
  const { isTooltipVisible, top, left, content } = useTooltip();

  return createPortal(
    isTooltipVisible ? (
      <S.TooltipContainer top={top} left={left}>
        <S.TooltipContent>{content}</S.TooltipContent>
      </S.TooltipContainer>
    ) : null,
    portalRef
  );
}
