import * as React from 'react';
import * as S from './FeatureToggle.theme';
import { Box } from '@uala/ui-core';

type Options<T> = {
  readonly label: string;
  readonly value: T;
  readonly icon?: React.ReactNode;
  readonly active: boolean;
};
type CheckedElementType = Pick<Options<string>, 'label' | 'value'>;

type ToggleItemsFn = (value: string) => (a: readonly Options<string>[]) => readonly Options<string>[];
type FilterItemsByActiveFn = (items: readonly Options<string>[]) => readonly CheckedElementType[];

export type FeatureToggleProps = {
  readonly itemMargin?: string;
  readonly options: readonly Options<string>[];
  readonly onChange: (checkedElemets: readonly CheckedElementType[]) => void;
};

const FeatureToggle = ({ itemMargin = '16px', options, onChange }: FeatureToggleProps): JSX.Element => {
  const [items, setItems] = React.useState<readonly Options<string>[]>(options);

  const toggleItems: ToggleItemsFn =
    value =>
    (elements): readonly Options<string>[] =>
      elements.map(element => (element.value === value ? { ...element, active: !element.active } : element));

  const filterItemsByActive: FilterItemsByActiveFn = elements =>
    elements
      .filter(({ active }) => active)
      .map(({ label, value }) => ({
        label,
        value,
      }));

  const handleChange = (value: string): void => {
    const toggleByCurrentValue = toggleItems(value);
    const updatedItems = toggleByCurrentValue(items);
    setItems(updatedItems);
    onChange(filterItemsByActive(updatedItems));
  };

  return (
    <Box display="flex">
      {items.map((item, idx) => (
        <S.FeatureToggleItem
          margin={itemMargin}
          key={`${idx}_${item.value}`}
          active={item.active}
          onClick={(): void => handleChange(item.value)}
        >
          {typeof item.icon === 'undefined' ? null : <Box mR={`calc(${itemMargin} / 3)`}>{item.icon}</Box>}
          <span>{item.label}</span>
        </S.FeatureToggleItem>
      ))}
    </Box>
  );
};
export { FeatureToggle };
