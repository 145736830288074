import * as React from 'react';
export interface ContextProps {
  readonly show: boolean;
  readonly setShow: (value: boolean) => void;
  readonly top: number;
  readonly setTop: (value: number) => void;
  readonly left: number;
  readonly setLeft: (value: number) => void;
  readonly content: string;
  readonly setContent: (value: string) => void;
}

export const TooltipContext = React.createContext<ContextProps>({} as ContextProps);

export const TooltipProvider: React.FC = ({ children }) => {
  const [show, setShow] = React.useState(false);
  const [top, setTop] = React.useState(0);
  const [left, setLeft] = React.useState(0);
  const [content, setContent] = React.useState('');

  const providerValues = React.useMemo(
    () => ({ show, setShow, top, setTop, left, setLeft, content, setContent }),
    [show, top, left, content]
  );

  return <TooltipContext.Provider value={providerValues}>{children}</TooltipContext.Provider>;
};
