import { Theme } from 'ui-core/src';
import { css } from '../lib';
import { FlexProps } from '../types';
import { SerializedStyles } from '../index';

import { resolveResponsiveProp } from './resolveResponsiveProp';

export const getFlexProps = (props: FlexProps & { readonly theme: Theme | null }): SerializedStyles => {
  const {
    flex,
    flexBasis,
    flexDirection,
    flexFlow,
    flexWrap,
    flexShrink,
    flexGrow,
    alignItems,
    justifyContent,
    theme,
  } = props;

  return css`
    ${flex ? resolveResponsiveProp({ theme, propName: 'flex', value: flex }) : undefined}
    ${flexBasis ? resolveResponsiveProp({ theme, propName: 'flex-basis', value: flexBasis }) : undefined}
    ${flexDirection ? resolveResponsiveProp({ theme, propName: 'flex-direction', value: flexDirection }) : undefined}
    ${flexFlow ? resolveResponsiveProp({ theme, propName: 'flex-flow', value: flexFlow }) : undefined}
    ${flexWrap ? resolveResponsiveProp({ theme, propName: 'flex-wrap', value: flexWrap }) : undefined}
    ${flexShrink ? resolveResponsiveProp({ theme, propName: 'flex-shrink', value: flexShrink }) : undefined}
    ${flexGrow ? resolveResponsiveProp({ theme, propName: 'flex-grow', value: flexGrow }) : undefined}
    ${alignItems ? resolveResponsiveProp({ theme, propName: 'align-items', value: alignItems }) : undefined}
    ${justifyContent ? resolveResponsiveProp({ theme, propName: 'justify-content', value: justifyContent }) : undefined}
  `;
};
