import { BaseProps, css, styled } from '@uala/ui-core';

const TagButton = styled.button<BaseProps>(
  ({
    theme: {
      typography: { fontSizes },
    },
    backgroundColor,
    color,
  }) => css`
    padding: 8px 20px;
    white-space: nowrap;
    border-radius: 50px;
    font-size: ${fontSizes.sm}px;
    // TODO: replace with color when palette will be ready
    background-color: ${backgroundColor};
    color: ${color};
    cursor: pointer;
    border: 0;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  `
);

export { TagButton };
