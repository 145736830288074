import { BaseProps, css, styled } from '@uala/ui-core';

const BadgeContainer = styled.span<BaseProps>(
  ({
    theme: {
      typography: { fontSizes },
    },
    backgroundColor,
    color,
    borderRadius,
  }) => css`
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 6px 16px;
    white-space: nowrap;
    border-radius: ${borderRadius};
    font-size: ${fontSizes.xs}px;
    // TODO: replace with color when palette will be ready
    background-color: ${backgroundColor};
    color: ${color};
  `
);

export { BadgeContainer };
