import { styled, BaseProps, getBaseProps, Theme, FontColors, LiteralUnion, ResponsiveProp } from '../../styled';

type Level = keyof Pick<HTMLElementTagNameMap, 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>;

type Props = {
  readonly level?: Level;
  readonly color: ResponsiveProp<LiteralUnion<FontColors, string>> & string;
} & BaseProps;

const config = (theme: Theme): Record<Level, number> => ({
  h1: theme.typography.fontSizes['2xl'],
  h2: theme.typography.fontSizes.xl,
  h3: theme.typography.fontSizes.lg,
  h4: theme.typography.fontSizes.md,
  h5: theme.typography.fontSizes.sm,
  h6: theme.typography.fontSizes.xs,
});

const HeadingProxy = styled.h1<Props>(({ level = 'h1', fontSize, theme, ...rest }) => ({
  ...getBaseProps({ theme, ...rest }),
  ...(!fontSize ? { fontSize: config(theme)[level] } : undefined),
}));

export { HeadingProxy };
export type { Props };
