import { PaddingProps } from '../types';
import { css } from '../lib';
import { resolveResponsiveProp } from './resolveResponsiveProp';
import { Theme } from 'ui-core/src';
import { SerializedStyles } from '../index';

export const getPaddingProps = (props: PaddingProps & { readonly theme: Theme | null }): SerializedStyles => {
  const {
    padding,
    p,
    paddingX,
    pX,
    paddingY,
    pY,
    pB,
    paddingBottom,
    pL,
    paddingLeft,
    pR,
    paddingRight,
    pT,
    paddingTop,
    theme,
  } = props;

  return css`
    ${p || padding ? resolveResponsiveProp({ theme, propName: 'padding', value: p || padding }) : undefined}
    ${pT || paddingTop ? resolveResponsiveProp({ theme, propName: 'padding-top', value: pT || paddingTop }) : undefined}
    ${pR || paddingRight
      ? resolveResponsiveProp({ theme, propName: 'padding-right', value: pR || paddingRight })
      : undefined}
    ${pB || paddingBottom
      ? resolveResponsiveProp({ theme, propName: 'padding-bottom', value: pB || paddingBottom })
      : undefined}
    ${pL || paddingLeft
      ? resolveResponsiveProp({ theme, propName: 'padding-left', value: pL || paddingLeft })
      : undefined}
    ${pX || paddingX
      ? css`
          ${resolveResponsiveProp({ theme, propName: 'padding-left', value: pX || paddingX })};
          ${resolveResponsiveProp({ theme, propName: 'padding-right', value: pX || paddingX })}
        `
      : undefined}
    ${pY || paddingY
      ? css`
          ${resolveResponsiveProp({ theme, propName: 'padding-top', value: pY || paddingY })};
          ${resolveResponsiveProp({ theme, propName: 'padding-bottom', value: pY || paddingY })}
        `
      : undefined}
  `;
};
