import * as React from 'react';
import { TooltipContext } from './TooltipContext';

type TooltipSetting = {
  readonly topPosition: number;
  readonly leftPosition: number;
  readonly tooltipContent: string;
};
type ShowTooltip = ({ topPosition, leftPosition, tooltipContent }: TooltipSetting) => void;
interface UseTooltip {
  readonly showTooltip: ShowTooltip;
  readonly hideTooltip: () => void;
  readonly isTooltipVisible: boolean;
  readonly top: number;
  readonly left: number;
  readonly content: string;
}

export function useTooltip(): UseTooltip {
  const context = React.useContext(TooltipContext);
  if (!context) throw new Error('useTooltip must be used within a TooltipProvider');
  const { show, setShow, top, setTop, left, setLeft, content, setContent } = context;

  const showTooltip = React.useCallback(
    ({ topPosition, leftPosition, tooltipContent }: TooltipSetting): void => {
      setTop(topPosition);
      setLeft(leftPosition);
      setShow(true);
      setContent(tooltipContent);
    },
    [setTop, setLeft, setShow, setContent]
  );

  const hideTooltip = (): void => {
    setShow(false);
  };

  return {
    showTooltip,
    hideTooltip,
    isTooltipVisible: show,
    top,
    left,
    content,
  };
}
