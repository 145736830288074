import * as React from 'react';

import {
  styled,
  BaseProps,
  AsElement,
  CSSProperties,
  getBaseProps,
  css,
  FontColors,
  LiteralUnion,
  Colors,
  resolveResponsiveProp,
  ResponsiveProp,
} from '../../styled';

export type TextProps = Omit<BaseProps, 'color'> &
  AsElement & {
    /**
     * @description
     * shorthand for textAlign
     */
    readonly align?: CSSProperties['textAlign'];
    /**
     * @description
     * shorthand for textTransform
     */
    readonly casing?: CSSProperties['textTransform'];
    /**
     * @description
     * shorthand for fontSize
     */
    readonly size?: BaseProps['fontSize'];
    /**
     * @description
     * use typography color scheme by default,
     * with graceful fallback on palette and hex string
     */
    readonly color?: ResponsiveProp<LiteralUnion<FontColors, string>> & string;
  };

const Text: React.FunctionComponent<TextProps> = styled.span<TextProps>(
  ({ align, casing, size, fontSize, theme, color, ...rest }) =>
    css`
      ${getBaseProps({ theme, fontSize: size || fontSize, ...rest })}
      ${align && { textAlign: align }}
      ${casing && { textTransform: casing }}
      ${color &&
      resolveResponsiveProp({
        theme,
        propName: 'color',
        value:
          theme.typography.colors[color as FontColors] ||
          theme.colors[color as Colors] ||
          color ||
          theme.body.fontColor,
      })}
    `
);

export { Text };
