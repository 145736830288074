import { BaseProps, css, styled } from '@uala/ui-core';

const FeatureToggleItem = styled.div<Pick<BaseProps, 'theme' | 'margin'> & { readonly active: boolean }>(
  ({
    theme: {
      typography: { fontSizes },
      colors,
    },
    margin,
    active,
  }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: ${fontSizes.xs}px;
    line-height: 1;
    color: ${active ? colors['teal.600'] : colors['navy.400']};
    margin-right: ${margin};

    :last-of-type {
      margin-right: 0;
    }
  `
);

export { FeatureToggleItem };
