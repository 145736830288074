import { CSSProperties } from '../lib';
import { Theme, Colors, Breakpoint, FontSizes } from '../theme';
import { DEFAULT_BREAKPOINT_KEY } from '../utils';
import { SpacingProps, OmitCssSpacingProps } from './spacingProps';
import { FlexProps, OmitFlexProps } from './flexProps';
import { GridProps, OmitGridProps } from './gridProps';
import { SizeProps, OmitSizeProps } from './sizeProps';

export type Primitive = null | undefined | string | number | boolean | symbol | bigint;

/**
 * @description
 * Create a union from an array of strings.
 *
 * @example
 * ```ts
 * const QUADRANTS = ['top', 'right', 'bottom', 'left'];
 *
 * type Quadrants = UnionStringArray<typeof QUADRANTS> // 'top' | 'right' | 'bottom' | 'left'
 * ```
 */
export type UnionStringArray<T extends Readonly<readonly string[]>> = T[number];

/**
 * @description
 * useful to make autocomplete works in cases like { color: Colors | string }
 *
 * @example
 * ```ts
 * const COLORS = {
 *  white: '#fff',
 *  black: '#000',
 * } as const
 *
 * type Colors = keyof typeof COLORS;
 *
 * type ColorsAndString = LiteralUnion<Colors, string>;
 * ```
 */
export type LiteralUnion<LiteralType, BaseType extends Primitive> = LiteralType | (BaseType & { readonly _?: never });

interface OverridedProps {
  readonly theme?: Theme;
  readonly color?: ResponsiveProp<LiteralUnion<Colors, string>>;
  readonly backgroundColor?: ResponsiveProp<LiteralUnion<Colors, string>>;
  readonly fontSize?: ResponsiveProp<LiteralUnion<FontSizes, string>>;
  readonly display?: ResponsiveProp<CSSProperties['display']>;
  readonly borderColor?: LiteralUnion<Colors, string>;
  readonly borderRadius?: ResponsiveProp<string>;
}

/*
|--------------------------------------------------------------------------
| SHORTHAND PROPS
|--------------------------------------------------------------------------
*/

interface ShorthandProps {
  // background
  readonly bgColor?: ResponsiveProp<LiteralUnion<Colors, string>>;
  readonly bg?: string;

  // Width
  readonly w?: ResponsiveProp<string>;

  // Height
  readonly h?: ResponsiveProp<string>;
}

/*
|--------------------------------------------------------------------------
| BASE PROPS
|--------------------------------------------------------------------------
*/

export interface BaseProps
  extends OverridedProps,
    ShorthandProps,
    SpacingProps,
    FlexProps,
    GridProps,
    SizeProps,
    Omit<
      CSSProperties,
      | OmitCssSpacingProps
      | OmitFlexProps
      | OmitGridProps
      | OmitSizeProps
      | 'translate'
      | 'color'
      | 'backgroundColor'
      | 'fontSize'
      | 'width'
      | 'height'
      | 'display'
      | 'borderColor'
      | 'borderRadius'
    > {}

/**
 * @description
 * Provide a list of props unrelated to styling, such: `onClick`, `as`, `children` and so on.
 * Mainly for preventing unwanted variables to be rendered on CSS.
 */
export const SYSTEM_RESERVED_PROPS_LIST = [
  'className',
  'data-testid',
  'children',
  'theme',
  'onClick',
  'theme',
  'as',
  'level',
  'ref',
] as const;

/**
 * @description
 * Define `SystemReservedProps` type.
 */
export type SystemReservedProps = UnionStringArray<typeof SYSTEM_RESERVED_PROPS_LIST>;

export interface AsElement {
  readonly as?:
    | keyof Pick<HTMLElementTagNameMap, 'span' | 'div' | 'section' | 'p' | 'article' | 'address' | 'label'>
    | keyof Pick<SVGElementTagNameMap, 'text'>;
}

export type ResponsivePropObj<T = unknown> = {
  readonly [DEFAULT_BREAKPOINT_KEY]: T;
} & { readonly [key in Breakpoint]?: T };

export type ResponsiveProp<T = unknown> = ResponsivePropObj<T> | T;

export type ResponsivePropKeys = keyof ResponsivePropObj<unknown>;
