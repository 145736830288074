import { BaseProps, css, styled } from '@uala/ui-core';

const Toggle = styled.div<Pick<BaseProps, 'borderRadius' | 'width' | 'height' | 'padding'>>(
  ({ borderRadius, width, height, padding }) => css`
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    width: ${width};
    height: ${height};
    border-radius: ${borderRadius};
    padding: ${padding} 0;

    input[type='checkbox'],
    label {
      position: absolute;
      left: 0;
      top: 0;
    }
  `
);

const Input = styled.input<Pick<BaseProps, 'backgroundColor' | 'height' | 'padding'>>(
  ({ backgroundColor, height, padding }) => css`
    margin: 0;
    padding: 0;
    opacity: 0;
    height: 0;
    width: 0;
    pointer-events: none;

    &:checked {
      ~ label {
        background-color: ${backgroundColor};
      }

      ~ label ~ .marker {
        left: calc(100% - ${height} + ${padding});
      }

      ~ label ~ .marker > svg {
        opacity: 1;
      }
    }

    &:disabled ~ label {
      cursor: not-allowed;
    }

    &:checked:disabled {
      ~ label,
      ~ label ~ .marker > svg {
        opacity: 0.3;
      }
    }

    &:not(:checked):disabled {
      ~ label {
        opacity: 0.3;
      }
    }
  `
);

const Label = styled.label<Pick<BaseProps, 'animationDuration'>>(
  ({ animationDuration, theme: { colors } }) => css`
    cursor: pointer;
    width: 100%;
    height: 100%;
    color: transparent;
    user-select: none;
    background-color: ${colors['navy.400']};
    border-radius: inherit;
    z-index: 1;
    transition: ${animationDuration};
    overflow: hidden;
  `
);

const Marker = styled.div<Pick<BaseProps, 'backgroundColor' | 'height' | 'padding' | 'animationDuration'>>(
  ({ backgroundColor, height, padding, animationDuration, theme: { colors } }) => css`
    position: relative;
    background-color: ${colors.white};
    width: calc(${height} - ${padding} * 2);
    height: calc(${height} - ${padding} * 2);
    border-radius: 50%;
    z-index: 2;
    pointer-events: none;
    left: ${padding};
    transition: left ${animationDuration};
    will-change: left;

    > svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(${height} / 1.6);
      opacity: 0;
      color: ${backgroundColor};
    }
  `
);

export { Toggle, Input, Label, Marker };
