import { styled, css, BaseProps, getBaseProps } from '../../styled';
import { setTextAlignments } from './Table.helpers';

type RowProps = { readonly sizes: string; readonly alignments: readonly string[] };

export const Table = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const RowGroup = styled.div`
  > [role='row']:not(:last-of-type) {
    border-bottom: 1px solid #dce0e8;
  }
`;

export const Row = styled.div<RowProps>(
  ({ sizes, alignments }) => css`
    display: grid;
    grid-template-columns: ${sizes};

    ${setTextAlignments(alignments)}
  `
);

export const Cell = styled.div<BaseProps>(
  props => css`
    padding: 12px;
    font-size: inherit;
    color: inherit;

    ${getBaseProps(props)}
  `
);
