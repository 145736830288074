export const setTextAlignments = (alignments: readonly string[]): string => {
  const childIndex = (i: number): number => i + 1;

  return alignments
    .map(
      (align, i) => `
          > [role='cell']:nth-of-type(${childIndex(i)}),
          > [role='columnheader']:nth-of-type(${childIndex(i)}) {
            text-align: ${align};
          }
      `
    )
    .join('\n');
};
