import { Theme } from 'ui-core/src';
import { css } from '../lib';
import { MarginProps } from '../types';
import { SerializedStyles } from '../index';
import { resolveResponsiveProp } from './resolveResponsiveProp';

export const getMarginProps = (props: MarginProps & { readonly theme: Theme | null }): SerializedStyles => {
  const {
    margin,
    m,
    marginX,
    mX,
    marginY,
    mY,
    mB,
    marginBottom,
    mL,
    marginLeft,
    mR,
    marginRight,
    mT,
    marginTop,
    theme,
  } = props;

  return css`
    ${m || margin ? resolveResponsiveProp({ theme, propName: 'margin', value: m || margin }) : undefined}
    ${mT || marginTop ? resolveResponsiveProp({ theme, propName: 'margin-top', value: mT || marginTop }) : undefined}
    ${mR || marginRight
      ? resolveResponsiveProp({ theme, propName: 'margin-right', value: mR || marginRight })
      : undefined}
    ${mB || marginBottom
      ? resolveResponsiveProp({ theme, propName: 'margin-bottom', value: mB || marginBottom })
      : undefined}
    ${mL || marginLeft ? resolveResponsiveProp({ theme, propName: 'margin-left', value: mL || marginLeft }) : undefined}
    ${mX || marginX
      ? css`
          ${resolveResponsiveProp({ theme, propName: 'margin-left', value: mX || marginX })}
          ${resolveResponsiveProp({ theme, propName: 'margin-right', value: mX || marginX })}
        `
      : undefined}
    ${mY || marginY
      ? css`
          ${resolveResponsiveProp({ theme, propName: 'margin-top', value: mY || marginY })}
          ${resolveResponsiveProp({ theme, propName: 'margin-bottom', value: mY || marginY })}
        `
      : undefined}
  `;
};
