import { BaseProps, getBaseProps, styled } from '@uala/ui-core';
import { treatwellProTheme } from '../../styled';

const defaultProviderTheme: BaseProps = {
  margin: '0',
  height: '1px',
  backgroundColor: treatwellProTheme.colors['navy.200'],
  border: 'none',
};

const Divider = styled.hr<BaseProps>(props => ({ ...getBaseProps({ ...defaultProviderTheme, ...props }) }));

export { Divider };
