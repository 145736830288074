import { Theme } from 'ui-core/src';
import { css } from '../lib';
import { SizeProps } from '../types';
import { resolveResponsiveProp } from './resolveResponsiveProp';
import { SerializedStyles } from '../index';

export const getSizeProps = (props: SizeProps & { readonly theme: Theme | null }): SerializedStyles => {
  const { width, height, minHeight, minWidth, maxHeight, maxWidth, theme } = props;

  return css`
    ${width ? resolveResponsiveProp({ theme, propName: 'width', value: width }) : undefined}
    ${height ? resolveResponsiveProp({ theme, propName: 'height', value: height }) : undefined}
    ${minHeight ? resolveResponsiveProp({ theme, propName: 'min-height', value: minHeight }) : undefined}
    ${minWidth ? resolveResponsiveProp({ theme, propName: 'min-width', value: minWidth }) : undefined}
    ${maxHeight ? resolveResponsiveProp({ theme, propName: 'max-height', value: maxHeight }) : undefined}
    ${maxWidth ? resolveResponsiveProp({ theme, propName: 'max-width', value: maxWidth }) : undefined}
  `;
};
