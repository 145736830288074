const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  white: '#FFFFFF',
  black: '#000000',
  // Coral
  'coral.600': '#FF6668',
  'coral.700': '#B24748',
  'coral.400': '#FF9395',
  'coral.200': '#FFC1C2',
  'coral.100': '#FFEFEF',
  // Blue
  'blue.600': '#4036ED',
  'blue.700': '#2C25A5',
  'blue.400': '#7972F2',
  'blue.200': '#B2AEF7',
  'blue.100': '#EBEAFD',
  // Pink
  'pink.600': '#FFB8BE',
  'pink.700': '#B28085',
  'pink.400': '#FFCDD1',
  'pink.200': '#FFE2E5',
  'pink.100': '#FFF7F8',
  // Yellow
  'yellow.600': '#FFCB4D',
  'yellow.700': '#B28E35',
  'yellow.400': '#FFDA82',
  'yellow.200': '#FFEAB7',
  'yellow.100': '#FFF9ED',
  // Teal
  'teal.600': '#11C6CF',
  'teal.700': '#0B8A90',
  'teal.400': '#58D7DD',
  'teal.200': '#9FE8EB',
  'teal.100': '#E7F9FA',
  // Navy/Grays (Design decided to use them instead of grays)
  'navy.800': '#071948',
  'navy.700': '#36456B',
  'navy.600': '#515E7F',
  'navy.500': '#838CA3',
  'navy.400': '#B5BAC8',
  'navy.300': '#E6E8ED',
  'navy.200': '#F3F4F6',
  'navy.100': '#F8F8FA',
  // Purple
  'purple.600': '#8E5AFF',
  'purple.700': '#633EB2',
  'purple.400': '#AF8BFF',
  'purple.200': '#D1BDFF',
  'purple.100': '#F3EEFF',
} as const;

const typography = {
  fontFamilies: {
    default: '"Treatwell Sans Web", sans-serif',
  },
  fontWeights: {
    normal: 400,
    bold: 700,
  },
  fontSizes: {
    '2xl': 32,
    xl: 28,
    lg: 24,
    md: 16,
    sm: 14,
    xs: 12,
    '2xs': 10,
  },
  colors: {
    default: colors['black'],
  },
};

const spacing = {
  xs: '8px',
  sm: '14px',
  md: '20px',
  lg: '24px',
  xl: '28px',
};

const breakpoints = {
  xs: 480,
  sm: 768,
  md: 1024,
  lg: 1336,
};

// TODO: this object should be generated automatically via script
const theme = {
  boxShadows: {},
  breakpoints,
  colors,
  spacing,
  typography,
  body: {
    bgColor: colors['navy.100'],
    fontFamily: typography.fontFamilies.default,
    fontSize: typography.fontSizes.md,
    fontColor: typography.colors.default,
  },
} as const;

const treatwellProTheme = { ...theme } as const;

export type TreatwellProTheme = typeof treatwellProTheme;

export { treatwellProTheme };
