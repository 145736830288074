import { ReactNode } from 'react';
import { styled, BaseProps, getBaseProps } from '../../styled';

export interface BoxProps extends BaseProps {
  readonly children?: ReactNode;
  readonly color?: BaseProps['color'] & string;
}

const Box = styled.div<BoxProps>(props => ({ ...getBaseProps(props) }));

export { Box };
