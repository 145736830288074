import * as React from 'react';
import { useTooltip } from './useTooltip.hook';
import { Box, useOnClickOutside } from '@uala/ui-core';

export const InfoTooltip: React.FC<{ readonly content: string }> = ({
  content,
  children = (
    <svg width={16} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
      <path d="M8.5 17a8.5 8.5 0 1 1 0-17 8.5 8.5 0 0 1 0 17Zm0-1a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Zm0-8.5a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1Zm0-3a1 1 0 1 1 0 2 1 1 0 1 1 0-2Z" />
    </svg>
  ),
}) => {
  const TOP_OFFSET = 35;

  const ref: React.RefObject<HTMLDivElement> = React.useRef(null);
  const { showTooltip, hideTooltip } = useTooltip();

  const handleTooltip = React.useCallback(
    (elRef: React.RefObject<HTMLDivElement>, topOffset: number): void => {
      const boundingClient = elRef?.current?.getBoundingClientRect();
      if (!boundingClient) return;
      showTooltip({
        topPosition: boundingClient.y + window.scrollY + topOffset,
        leftPosition: boundingClient.x + window.scrollX,
        tooltipContent: content,
      });
    },
    [content, showTooltip]
  );

  useOnClickOutside(ref, hideTooltip);

  return (
    <Box
      cursor="pointer"
      width="50px"
      textAlign="center"
      onClick={(): void => handleTooltip(ref, TOP_OFFSET)}
      ref={ref}
    >
      {children}
    </Box>
  );
};
