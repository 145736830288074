import { styled } from '@uala/ui-core';

export const TooltipContent = styled.div`
  position: relative;
  min-width: 200px;
  min-height: 50px;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 8px solid ${({ theme: { colors } }): string => colors['navy.700']};
    border-radius: 10%;
    top: -22px;
    left: 0;
  }
`;
export const TooltipContainer = styled.div<{ readonly top: number; readonly left: number }>`
  position: absolute;
  top: ${({ top }): number => top || 0}px;
  left: ${({ left }): number => left || 0}px;
  max-width: 500px;
  background: ${({ theme: { colors } }): string => colors['navy.700']};
  color: white;
  border-radius: 10px;
  padding: 16px;
`;
