import { BaseProps, css, styled } from '@uala/ui-core';

const IconButtonWrapper = styled.button<BaseProps>(
  ({ borderColor, backgroundColor }) => css`
    position: relative;
    background-color: ${backgroundColor};
    border-radius: 50%;
    border: 1px solid ${borderColor};
    cursor: pointer;
    width: 42px;
    height: 42px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  `
);

const Alert = styled.div<BaseProps>(
  ({ color, backgroundColor }) => css`
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: ${backgroundColor};
    border-radius: 50%;
    color: ${color};
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  `
);

export { IconButtonWrapper, Alert };
