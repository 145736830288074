import { Theme } from 'ui-core/src';
import { css } from '../lib';
import { SpacingProps } from '../types';
import { getMarginProps } from './getMarginProps';
import { getPaddingProps } from './getPaddingProps';
import { SerializedStyles } from '../index';

export const getSpacingProps = (props: SpacingProps & { readonly theme: Theme | null }): SerializedStyles => {
  const {
    // margin props
    margin,
    m,
    marginX,
    mX,
    marginY,
    mY,
    mB,
    marginBottom,
    mL,
    marginLeft,
    mR,
    marginRight,
    mT,
    marginTop,
    // padding props
    padding,
    p,
    paddingX,
    pX,
    paddingY,
    pY,
    pB,
    paddingBottom,
    pL,
    paddingLeft,
    pR,
    paddingRight,
    pT,
    paddingTop,
    theme,
  } = props;

  return css`
    ${getMarginProps({
      margin,
      m,
      marginX,
      mX,
      marginY,
      mY,
      mB,
      marginBottom,
      mL,
      marginLeft,
      mR,
      marginRight,
      mT,
      marginTop,
      theme,
    })};
    ${getPaddingProps({
      padding,
      p,
      paddingX,
      pX,
      paddingY,
      pY,
      pB,
      paddingBottom,
      pL,
      paddingLeft,
      pR,
      paddingRight,
      pT,
      paddingTop,
      theme,
    })}
  `;
};
