import { Colors } from '../theme';
import { css, SerializedStyles, CSSObject } from '../lib';
import { BaseProps, SYSTEM_RESERVED_PROPS_LIST, SystemReservedProps } from '../types';

import { getSpacingProps } from './getSpacingProps';
import { getFlexProps } from './getFlexProps';
import { getGridProps } from './getGridProps';
import { getSizeProps } from './getSizeProps';
import { resolveResponsiveProp } from './resolveResponsiveProp';

export const getBaseProps = (props: BaseProps): SerializedStyles => {
  const {
    backgroundColor,
    bg,
    bgColor,
    color,
    w,
    h,
    fontSize,
    theme = null,
    display,
    borderRadius,
    // size
    width,
    height,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    // margin
    margin,
    marginX,
    marginY,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    m,
    mB,
    mL,
    mR,
    mT,
    mX,
    mY,
    // padding
    p,
    pB,
    pL,
    pR,
    pT,
    pX,
    pY,
    padding,
    paddingX,
    paddingY,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    // flex
    flex,
    flexBasis,
    flexDirection,
    flexFlow,
    flexWrap,
    flexShrink,
    flexGrow,
    alignItems,
    justifyContent,
    // grid
    grid,
    gap,
    gridRowEnd,
    gridTemplate,
    gridTemplateColumns,
    gridTemplateAreas,
    gridRow,
    gridColumnStart,
    gridColumn,
    gridAutoRows,
    gridAutoFlow,
    gridAutoColumns,
    gridArea,
    gridTemplateRows,
    gridColumnEnd,
    gridRowStart,
    borderColor,
    ...rest
  } = props;

  const filteredProps: CSSObject = Object.keys(rest)
    .filter(key => !SYSTEM_RESERVED_PROPS_LIST.includes(key as unknown as SystemReservedProps))
    .reduce(
      (obj, key) => ({
        ...obj,
        // FIXME: Understand why TSC is compling about it.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [key]: rest[key],
      }),
      {}
    );

  return css`
    ${filteredProps};

    ${getSpacingProps({
      // margin
      margin,
      m,
      marginX,
      mX,
      marginY,
      mY,
      mB,
      marginBottom,
      mL,
      marginLeft,
      mR,
      marginRight,
      mT,
      marginTop,
      // padding
      padding,
      p,
      paddingX,
      pX,
      paddingY,
      pY,
      pB,
      paddingBottom,
      pL,
      paddingLeft,
      pR,
      paddingRight,
      pT,
      paddingTop,
      theme,
    })}

    ${getFlexProps({
      flex,
      flexBasis,
      flexDirection,
      flexFlow,
      flexWrap,
      flexShrink,
      flexGrow,
      alignItems,
      justifyContent,
      theme,
    })}

    ${getGridProps({
      grid,
      gap,
      gridRowEnd,
      gridTemplate,
      gridTemplateColumns,
      gridTemplateAreas,
      gridRow,
      gridColumnStart,
      gridColumn,
      gridAutoRows,
      gridAutoFlow,
      gridAutoColumns,
      gridArea,
      gridTemplateRows,
      gridColumnEnd,
      gridRowStart,
      theme,
    })}
    
    ${getSizeProps({ width: w || width, height: h || height, minWidth, minHeight, maxWidth, maxHeight, theme })}

    ${bg ? { background: bg } : undefined}

    ${bgColor || backgroundColor
      ? resolveResponsiveProp({
          theme,
          propName: 'background-color',
          value: theme?.colors[(bgColor || backgroundColor) as Colors] || bgColor || backgroundColor,
        })
      : undefined}

    ${display ? resolveResponsiveProp({ theme, propName: 'display', value: display }) : undefined}
    
    ${borderRadius ? resolveResponsiveProp({ theme, propName: 'border-radius', value: borderRadius }) : undefined}
    
    
    ${fontSize
      ? resolveResponsiveProp({
          theme,
          propName: 'font-size',
          value: fontSize,
          themeSlice: theme?.typography.fontSizes,
          unitSize: 'px',
        })
      : undefined}

    ${color
      ? resolveResponsiveProp({ theme, propName: 'color', value: theme?.colors[color as Colors] || color })
      : undefined}
    ${borderColor ? { borderColor: theme?.colors[borderColor as Colors] || borderColor } : undefined}
  `;
};
