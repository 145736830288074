import { BaseProps, css, styled } from '@uala/ui-core';

const Wrapper = styled.div<Pick<BaseProps, 'backgroundColor' | 'borderRadius' | 'padding'>>(
  ({ backgroundColor, borderRadius, padding }) => css`
    position: relative;
    flex-shrink: 0;
    background-color: ${backgroundColor};
    border-radius: ${borderRadius};
    padding: ${padding};
  `
);

const CloseButton = styled.button<Pick<BaseProps, 'backgroundColor' | 'borderRadius'>>(
  ({ backgroundColor, borderRadius }) => css`
    background-color: ${backgroundColor};
    border-radius: ${borderRadius};
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border: 0;
    cursor: text;
    width: 36px;
    height: 36px;
    margin: 0 0 0 20px;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  `
);

const Title = styled.h4<Pick<BaseProps, 'color'>>(
  ({
    theme: {
      typography: { fontSizes },
    },
    color,
  }) => css`
    font-size: ${fontSizes.sm}px;
    color: ${color};
    margin: 0;
  `
);

const NoteWrapper = styled.div(css`
  position: relative;
  padding: 8px;
`);

const Input = styled.input<Pick<BaseProps, 'color'>>(
  ({
    theme: {
      typography: { fontSizes },
    },
    color,
  }) => css`
    width: 100%;
    color: ${color};
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    font-size: ${fontSizes.sm}px;

    &:focus {
      outline: 0;
      box-shadow: none;

      & + label {
        font-size: ${fontSizes['2xs']}px;
        line-height: ${fontSizes['2xs']}px;
        cursor: default;
      }
    }

    &:valid {
      & + label {
        font-size: ${fontSizes['2xs']}px;
        line-height: ${fontSizes['2xs']}px;
        cursor: default;
      }
    }
  `
);

const Label = styled.label<Pick<BaseProps, 'color' | 'opacity' | 'bottom'>>(
  ({
    theme: {
      typography: { fontSizes },
    },
    color,
    opacity,
    bottom,
  }) => css`
    opacity: ${opacity};
    color: ${color};
    display: block;
    font-size: ${fontSizes.xs}px;
    line-height: ${fontSizes.xs}px;
    position: absolute;
    z-index: 2;
    bottom: ${bottom}px;
    left: 8px;
    transition: all 0.3s ease;
    cursor: pointer;
  `
);

const Features = styled.div(css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 15px 0 0;
`);

const Feature = styled.div<Pick<BaseProps, 'borderColor'>>(
  ({
    theme: {
      typography: { fontSizes },
    },
    borderColor,
  }) => css`
    flex-shrink: 0;
    border: 1px solid ${borderColor};
    padding: 8px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-size: ${fontSizes.sm}px;
    line-height: ${fontSizes.sm}px;
    margin: 0 16px 0 0;

    &:last-child {
      margin: 0;
    }
  `
);

export { Wrapper, CloseButton, Title, NoteWrapper, Input, Label, Features, Feature };
